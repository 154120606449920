import axiosClient from '@/axios'

const routeAuthenticated = '/auth/plans';

const index = async () => {
   return await axiosClient.get(`${routeAuthenticated}/index`);
}

const get = async uuid => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
}

export default {
   index,
   get
}
